import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from '../services/api.service';
import { AlertController, ToastController, LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@capacitor/storage';

@Component({
  selector: 'app-order',
  templateUrl: './order.page.html',
  styleUrls: ['./order.page.scss'],
  providers: [ApiService]
})
export class OrderPage implements OnInit {

  @Input() list;
  public notes = "";

  constructor(public modalController: ModalController, private alertController: AlertController, private apiService: ApiService, public toastController: ToastController, public loadingController: LoadingController, private http: HttpClient) { }

  ngOnInit() {

  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true,
      'list': this.list
    });
  }

  remove(i) {
    const index = this.list.indexOf(i);
    if (index > -1) {
      this.list.splice(index, 1);
    }
    if (this.list.length == 0) {
      this.dismiss();
    }
  }


  async sendOrder() {
    const loading = await this.loadingController.create({
      message: 'Cargando productos...',
    });
    await loading.present();
    const { value } = await Storage.get({ key: 'user' });
    (await this.apiService.sendOrder(this.list, this.notes)).subscribe(
      async (response) => {
        this.list = []
        this.http.post('https://diloa.appcloud.es/logger-7swooe7n_new.php', JSON.stringify({ "details": `user ${JSON.parse(value)[0].CODCLI.trim()}: Pedido enviado correctamente` })).subscribe(data => { })
        const toast = await this.toastController.create({
          message: 'Pedido enviado con exito',
          duration: 3000,
          color: 'success',
          buttons: [
            {
              icon: 'close',
              role: 'cancel',
              handler: () => {
              }
            }
          ]
        });
        toast.present();
        this.dismiss();
        this.loadingController.dismiss();
      }, async (error) => {
        this.http.post('https://diloa.appcloud.es/logger-7swooe7n_new.php', JSON.stringify({ "details": `user ${JSON.parse(value)[0].CODCLI.trim()}: Fallo al enviar el pedido` })).subscribe(data => { })
        this.loadingController.dismiss();
        const alert = await this.alertController.create({
          header: 'Error',
          subHeader: 'Error al enviar tu pedido intentelo mas tarde',
          buttons: ['OK']
        });
        await alert.present();
      })
  }

}