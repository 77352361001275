import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Storage } from '@capacitor/storage';
@Injectable()
export class ApiService {
    public url: string;
    constructor(public _http: HttpClient) { }

    async getProducts(): Promise<Observable<any>> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this._http.get('https://diloa.duckdns.org:1515/api/products', { headers: headers });
    }

    async sendOrder(order, notes): Promise<Observable<any>> {
        const { value } = await Storage.get({ key: 'user' });
        let token = null;
        if (value) {
            token = JSON.parse(value);
        }
        let headers = new HttpHeaders({
            'Content-type': 'application/json',
        });

        let body = {
            "order": order,
            "codcli": token[0].CODCLI,
            "notes": notes,
        }
        return this._http.post('https://diloa.duckdns.org:1515/api/save', body, { headers: headers });
    }
}